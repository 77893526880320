@import "~font-awesome/css/font-awesome.min.css";

.stepMenu.ui-tabview-left > .ui-tabview-nav {
  width: 10%;
}

/** panels **/
.stepMenu.ui-tabview-left > .ui-tabview-panels {
  width: 90%;
}

.app-name {
  margin-left: 10px;
}

.logo {
  background: url('./images/Freight.png');
  height: 350px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  display: block;
  margin-left: auto;
  margin-right: auto;
  // width: 50%;
  background-size: contain;
}

::placeholder {

}

.toolbar .ui-inputtext {
  color: white;
}

span.sequence.ui-inputgroup-addon {
  background-color: gray;
  color: white;
}

.toolbar .ui-autocomplete .ui-autocomplete-input::-webkit-input-placeholder {
  color: #ffffff;
}

.toolbar .ui-autocomplete .ui-autocomplete-input::-moz-placeholder {
  color: #ffffff;
}

.toolbar .ui-autocomplete .ui-autocomplete-input::-ms-input-placeholder {
  color: #ffffff;
}

div#sequence {
  color: black;
}

div#sequence:hover {
  background-color: #7CB342;
}

.ui-float-label {
  width: 100%;
}

.ui-float-label > label {
  color: #607D8B;
}

body .ui-inputtext:disabled {
  border-bottom: 1px dotted;
  color: red;
}

.print {
  .ui-autocomplete-panel {
    width: 300px;
  }
}

@media print {
  body{
    background-color: white !important;
  }

  .no-print{
    display: none;
  }

  .print {
    font-size: 11px;
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 999999;
    height: 100%;

    i, button, .no-print, label {
      display: none;
    }

    .big-rect {
      height: 120px !important;
    }

    .small-rect {
      height: 50px !important;
    }

    .print-label {
      display: block !important;
    }

    &.report-file {
      font-family: "Courier New", Courier, monospace;
    }
  }
}

.wrap {
  overflow-wrap: break-word;
}

.ui-dataview .ui-dataview-header {
  display: none;
}

.line-details {
  .ui-treenode-label {
    width: 100%;
  }
}

.document-title {
  font-size: 1.17em;
  font-weight: bold;
}

.border-thick {
  border: 3px solid;
}

.border-thin {
  border: 1px solid;
}

.border-left {
  border-left: 1px solid;
}

.border-right {
  border-right: 1px solid;
}

.border-top {
  border-top: 1px solid;
}

.border-bottom {
  border-bottom: 1px solid;
}

.company-name {
  font-weight: bold;
  font-size: 18px;
}

.no-padding-top-bottom {
  padding-bottom: 0;
  padding-top: 0;
}

.text-capitalize {
  text-transform: capitalize;
}

.company-logo {
  height: 6em;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  display: block;
  margin-left: auto;
  margin-right: auto;
  // width: 50%;
  background-size: contain;
}

.ui-widget:disabled, .ui-state-disabled, .ui-inputtext:disabled {
  color: black !important;
  opacity: 0.8 !important;
}

.white-toggle-icon {
  .ui-accordion-toggle-icon {
    color: white !important;
  }
}

.ui-tabview {
  .ui-tabview-nav {
    > li.ui-state-active {
      a {
        color: black !important;
      }
    }
  }
}

.color-white {
  input {
    color: white !important;
  }
}

td {
  overflow-wrap: break-word;
}

.warning {
  background-color: transparent;
  border: 0 none;
  margin: 0px;
  color: #F9C349;
  font-size: .75em;
}
